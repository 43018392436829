import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import Axios from 'axios';
import { useAuth } from "../Context/Auth";
import { useAlert } from 'react-alert';
import MobileAppManagerModalAdd from './MobileAppManagerModalAdd.js';
import SignAppManagerModalAdd from './SignAppManagerModalAdd.js';
import FWAppManagerModalAdd from './FWAppManagerModalAdd.js';
import LoadingModal from '../Layout/LoadingModal.js';
import FileDownload from 'js-file-download';
import { confirmAlert } from 'react-confirm-alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import dayjs from 'dayjs';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const AppManager = () => {

  const alert = useAlert();
  const { session } = useAuth();

  const [loading, setLoading] = useState(false);
  const [labAppVersions, setLabAppVersions] = useState([]);
  const [signAppVersions, setSignAppVersions] = useState([]);
  const [fwAppVersions, setFWAppVersions] = useState([]);
  const [isModalAddOpen, setIsModalAddOpen] = useState(null);

  const isSuperAdmin = useMemo(() => session.role.includes('superAdmin'), [session]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    document.title = "MSTD Laboratorio - Aplicaciones";
  }, []);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { headers: { 'Content-Type': 'application/json' }, cancelToken: source.token };
    const requests = [Axios.get('/api/mobileApp/', opts), Axios.get('/api/signApp/', opts), Axios.get('/api/fieldWorkApp/', opts)]

    Axios.all(requests).then(Axios.spread((...responses) => {
      setLabAppVersions(responses[0].data);
      setSignAppVersions(responses[1].data);
      setFWAppVersions(responses[2].data);
      setLoading(false);
    })).catch(err => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    })
  }, [alert]);

  const DownloadAPK = (record, section) => {
    if (record) {
      setLoading(true);

      if (source) {
        source.cancel();
        source = CancelToken.source();
      }

      const opts = {
        headers: {
          'Content-Disposition': 'attachment'
        },
        responseType: 'blob',
        cancelToken: source.token
      };

      const urlRouteHelper = (section === 0 ? '/api/mobileApp/download/' : section === 1 ? '/api/signApp/download/' : '/api/fieldWorkApp/download/');
      const filenameHelper = (section === 0 ? 'MSTDLab ' : section === 1 ? 'MSTDSigner ' : 'MSTDTerreno ');
      const extHelper = (section === 1 ? '.rar' : '.apk');
      const url = (urlRouteHelper+record.version);
      const filename = filenameHelper + record.version + ' ' + (dayjs(record.releaseDate).format("DD-MM-YYYY")) + extHelper;

      Axios.get(url, opts)
      .then((res) => {
        FileDownload(res.data, filename);
        setLoading(false);
        alert.show('Se descargó el archivo con éxito', {type: 'success'});
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else if (err.response.status === 404) {
          alert.show('No se pudo descargar el archivo', {type: 'error'})
        } else if (err.response.status === 400) {
          alert.show('No se encontró el archivo', {type: 'error'})
        } else {
          alert.show('Ocurrió un error al realizar esta operación', {type: 'error'})
        }
      });
    } else {
      alert.show('No se encontró la información necesaria para descargar este archivo', {type: 'error'});
    }
  }

  const DeleteRecordStepOne = (record, section) => {
    if (record) {
      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
          return (
            <div className="modal-delete-confirm-container">
              <h5>Confirme esta acción</h5>
              <p>{'¿Está seguro(a) de eliminar la versión "'+record.version+'"?'}</p>
              <div className="modal-delete-confirm-buttons-container">
                <button
                  className="modal-confirm-button round-button silver-black me-2"
                  onClick={() => onClose()}
                >
                  No, deseo salir
                </button>
                <button
                  className="modal-confirm-button round-button lb-white"
                  onClick={() => {
                    onClose();
                    DeleteRecordStepTwo(record, section);
                  }}
                >
                  Si, deseo eliminar
                </button>
              </div>
            </div>
          );
        }
      });
    }
  }

  const DeleteRecordStepTwo = (record, section) => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { headers: { 'Content-Type': 'application/json' }, cancelToken: source.token };
    const url = (section === 0 ? '/api/mobileApp/delete/' : section === 1 ? '/api/signApp/delete/' : '/api/fieldWorkApp/delete/');

    Axios.post(url, {id: record._id}, opts)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success'});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const RenderSingleItem = (item, sectionIdx) => {
    return (
      <div className="bg-gw-lb p-2">
        <div className="d-flex align-items-center mb-2">
          <div>{'Versión: ' + item.version}</div>
          <div className="mx-2" title={item.releaseDate ? (item.releaseDate.split('T')[0].split('-').reverse().join('-')) : '-'}>
            {item.releaseDate ? (dayjs().to(dayjs(item.releaseDate))) : '-'}
          </div>
          <Button className="rounded-2 cursor-pointer fs-inherit" size='sm' onClick={() => DownloadAPK(item, sectionIdx)}>
            Descargar
          </Button>
          { isSuperAdmin ? 
            <Button className="rounded-2 cursor-pointer fs-inherit ms-3" size='sm' variant='secondary' onClick={() => DeleteRecordStepOne(item, sectionIdx)}>
              Eliminar
            </Button>
          : null }
        </div>
        <div>{'Nota: ' + item.comment}</div>
      </div>
    )
  }

  const RenderData = () => {
    const sections = [
      { title: 'Laboratorio', items: labAppVersions },
      { title: 'Firmas', items: signAppVersions },
      { title: 'Terreno', items: fwAppVersions }
    ]

    return (
      sections.map((section, sectionIdx) => (
        <div key={sectionIdx} className="d-flex flex-1 flex-column align-items-center m-2">
          <Card className="d-flex w-100 bg-white">
            <Card.Body>
              <Card.Title className="d-flex align-items-center justify-content-center mb-3">
                {section.title}
                { isSuperAdmin ? 
                  <Button className="rounded-2 cursor-pointer ms-2" size='sm' onClick={() => setIsModalAddOpen((sectionIdx+1))}>
                    Nuevo
                  </Button> 
                : null }
              </Card.Title>
              { section.items.length ? 
                <>
                  {RenderSingleItem(section.items[0], sectionIdx)}
                  <Accordion>
                    <Accordion.Item eventKey={sectionIdx}>
                      <Accordion.Header className="mt-2 fs-7">{'Mostrar más (' + (section.items.length-1) + ')'}</Accordion.Header>
                      <Accordion.Body>
                        {section.items.filter((item, itemIdx) => itemIdx !== 0).map((item, itemIdx) => (
                          <div key={itemIdx} className="mb-3">
                            {RenderSingleItem(item, sectionIdx)}
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              : 
                <div> No se encontraron versiones para esta aplicación </div>
              }
            </Card.Body>
          </Card>
        </div>
      ))
    )
  }

  useEffect(() => {
    if (isFirstRender.current) {
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="outer-container">
      <LoadingModal loading={loading}/>
      {isModalAddOpen === 1 ? <MobileAppManagerModalAdd closeModal={() => { setIsModalAddOpen(null); GetData() }}/> : null}
      {isModalAddOpen === 2 ? <SignAppManagerModalAdd closeModal={() => { setIsModalAddOpen(null); GetData() }}/> : null}
      {isModalAddOpen === 3 ? <FWAppManagerModalAdd closeModal={() => { setIsModalAddOpen(null); GetData() }}/> : null}
      <div className="table-inner-container mt-3">
        <div className="d-flex justify-content-center my-2 py-1">
          <h5 className="m-0"> Aplicaciones MSTD </h5>
        </div>
        <div className="d-flex flex-1 flex-column flex-lg-row">
          {RenderData()}
        </div>
      </div>
    </div>
  )
}

export default AppManager
