import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import LoadingModal from '../Layout/LoadingModal.js';
import { FaCloudDownloadAlt, FaUpload } from 'react-icons/fa';
import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import FileDownload from 'js-file-download';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import dayjs from 'dayjs';

const rowTitles = ['# Cápsula', 'Peso Cápsula'];

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const CapsulesEq = () => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const isFirstRender = useRef(true);

  const GetData = useCallback(() => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/extras/taras';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setData(res.data?.data || []);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert]);

  const DownloadData = () => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Disposition': 'attachment'
      },
      responseType: 'blob',
      cancelToken: source.token
    };

    const url = '/api/downloads/extras/capsules';
    const filename = 'MSTD Cápsulas ' + (dayjs().format("DD-MM-YY")) + '.xlsx';

    Axios.get(url, opts)
    .then((res) => {
      FileDownload(res.data, filename);
      setLoading(false);
      alert.show('Se descargó el archivo con éxito', {type: 'success'});
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else if (err.response.status === 404) {
        alert.show('No se pudo descargar el archivo', {type: 'error'})
      } else if (err.response.status === 400) {
        alert.show('No se encontró el archivo', {type: 'error'})
      } else {
        alert.show('Ocurrió un error al realizar esta operación', {type: 'error'})
      }
    });
  }

  const ReadCapsulesFile = (file) => {

    if (file) {

      setLoading(true);

      const formData = new FormData();
      formData.append('filename', file);

      // Add new sample with reports to be made or added to
      const opts = { cancelToken: source.token };
      const url = '/api/extras/capsules/read';
      Axios.post(url, formData, opts)
      .then((res) => {
        setLoading(false);
        SaveNewDataStepOne(res.data);
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      });
    } else {
      alert.show('Seleccione su archivo para subir', {type: 'info'});
    }
  }

  const SaveNewDataStepOne = (newData) => {
    if (newData.length) {
      
      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
          return (
            <div className="modal-delete-confirm-container">
              <h4>Actualizar Cápsulas</h4>
              <p>Confirme para guardar los siguientes valores de cápsulas: </p>
              <div className="d-flex flex-column overflow-auto" style={{ maxHeight: '50vh' }}>
                <Table striped bordered hover>
                  <thead>
                    <tr> 
                      {rowTitles.map((item, itemIdx) => <th key={itemIdx}>{item}</th>)} 
                    </tr>
                  </thead>
                  <tbody>
                    {newData.map((item, itemIdx) => (
                      <tr key={itemIdx}>
                        <td>{(itemIdx+1)}</td>
                        <td>{item.weight || ''}</td>
                      </tr> 
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex mt-3">
                <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> No, deseo salir </Button>
                <Button className="flex-1 lb-white" onClick={() => { onClose(); SaveDataStepTwo(newData) }}> Si, deseo guardar </Button>
              </div>
            </div>
          );
        }
      })
    } else {
      alert.show('No se encontraron datos para guardar', {type: 'info'})
    }
  }

  const SaveDataStepTwo = (newData) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/extras/saveData';

    Axios.post(url, { code: 'taras', data: newData }, opts)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success'});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  useEffect(() => {
    if (isFirstRender.current) {
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <>
      <LoadingModal loading={loading}/>
      <div className="d-flex flex-column flex-sm-row overflow-auto">
        <div className="modal-table-outer-container flex-1 m-2">
          <div className="modal-table-row-inner-container">
            <div className="modal-table-sticky-header-container">
              <div className="modal-table-sticky-header">
                { rowTitles.map((item, itemIdx) => <div key={itemIdx} className="modal-table-row-container"> {item} </div> )}
              </div>
            </div>
            {
              data.length ?
                data.map((item, itemIdx) => {
                  return (
                    <div key={itemIdx} className="modal-table-row-input-container">
                      <div className="modal-table-row-container">
                        <span className="modal-table-title"> {itemIdx+1} </span>
                      </div>
                      <div className="modal-table-row-container">
                        <span className="modal-table-title"> {item.weight || '-'} </span>
                      </div>
                    </div>
                  )
                })
              :
                <span className="modal-table-loading-text"> No hay información para mostrar </span>
            }
          </div>
        </div>
        <div className="d-flex flex-column flex-1">
          <div className="my-2 ms-2">
            <span>Para modificar los datos de las taras, descargue el documento, realice los cambios 
            en la misma planilla y luego cargue el documento con TODAS las cápsulas.</span>
          </div>
          <div className="my-2 ms-2">
            <span>Para finalizar se mostrará una vista previa de los datos encontrados en el archivo.</span>
          </div>
          <div className="d-flex justify-content-around align-items-center my-2 ms-2">
            <div className="d-flex justify-content-center align-items-center p-1">
              <Button className="d-flex p-2 rounded-2 bg-lb-white" disabled={loading || !data.length} title="Descargar" onClick={() => DownloadData()}>
                <FaCloudDownloadAlt color="white" size={17} className="icon-style"/>
                <span className="fs-7 ms-1">Descargar</span>
              </Button>
            </div>
            {
              !loading ? 
                <label>
                  <div className="">
                    <input
                      type="file"
                      className="d-none"
                      accept=".xlsx"
                      onChange={e => ReadCapsulesFile(e.target.files[0])}
                    />
                    <div className="btn d-flex p-2 rounded-2 bg-lb-white">
                      <FaUpload color="white" size={17} className="icon-style"/>
                      <span className="fs-7 ms-1">{'Subir Archivo(.xlsx)'}</span>
                    </div>
                  </div>
                </label>
              : 
                <Button className="d-flex p-2 rounded-2 bg-lb-white" disabled >
                  <FaUpload color="white" size={17} className="icon-style"/>
                  <span className="fs-7 ms-1">{'Subir Archivo(.xlsx)'}</span>
                </Button>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default CapsulesEq;