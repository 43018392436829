import React, { useState } from 'react';
import { ModalHeader, ModalSections } from '../Layout/ModalElements';
import CbrMold from './CbrMold';
import ProctorMold from './ProctorMold';
import PicEq from './PicEq';
import RdEq from './RdEq';
import FitCurveEq from './FitCurveEq';
import CapsulesEq from './CapsulesEq';

const viewSections = ['Moldes CBR', 'Moldes Proctor', 'Picnómetros', 'Densidad Relativa', 'Curva de Ajuste', 'Cápsulas'];

const EquipmentModalEditInfo = ({ closeModal }) => {

  const [activeSection, setActiveSection] = useState(0);

  return (
    <div className="modal-outer-container">
      <div className="modal-inner-container-large">
        <ModalHeader title={'Información Equipo'} closeModal={closeModal} />
        <ModalSections sections={viewSections} activeSection={activeSection} setActiveSection={(value) => setActiveSection(value)} />
        {activeSection === 0 ? <CbrMold/> : null}
        {activeSection === 1 ? <ProctorMold/> : null}
        {activeSection === 2 ? <PicEq/> : null}
        {activeSection === 3 ? <RdEq/> : null}
        {activeSection === 4 ? <FitCurveEq/> : null}
        {activeSection === 5 ? <CapsulesEq/> : null}
      </div>
    </div>
  )
}

export default EquipmentModalEditInfo
